import React, { useState, useEffect, useContext } from "react";
import "src/scss/main.css";
import {
  Box,
  Typography,
  TextField,
  Grid,
  makeStyles,
  Button,
  Link,
  FormHelperText,
} from "@material-ui/core";
import styled from "styled-components";
import OtpInput from "react-otp-input";
import Logo from "src/component/Logo";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { useHistory, Link as RouterComponent } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { calculateTimeLeft } from "./timer";
import TopBar from "src/layouts/LoginLayout/index.js";
import Countdown from "react-countdown";
import { ToastContainer } from "react-toastify";

import Page from "src/component/Page";
import { toast } from "react-toastify";
import axios from "axios";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import moment from "moment";
import {BASE_URL} from "../../../config/APICongig"
import { AuthContext } from "src/context/Auth";
import CustomToast from "../../../component/customToast";
const useStyles = makeStyles((theme) => ({
  logosec: {
    "@media(min-width:1280px)": {
      display: "none",
    },
  },
  textfiled: {
    width: "10px",
    height: "40px",
    background: "rgba(217, 217, 217, 0.1)",
    border: "1px solid #D4D4D4",
    borderRadius: "3px",
  },
  submit: {
    width: "374.3px",
    height: "41.73px",
    background: "#F2542D",
    margin: "26px 10px 30px 64px",
    borderRadius: "7px",
    border: " 3px solid #F2542D",
    "&:hover": {
      background: "#fff",
      color: "#F2542D",
      border: " 3px solid #F2542D",
    },
  },
  forgot: {
    display: "flex",
    justifyContent: "flex-end",
  },
  OtpDiv: {
    marginTop: "2rem",
    paddingBottom: "3rem",
    paddingLeft: "15px",
  },

  inputBlocks: {
    paddingLeft: "13px",
    marginTop: "14px",
  },
  inputFields: {},
  error: {
    margin: "10px 0px 0px 71px",
    letterSpacing: "0.2px",
    fontFamily: "Inter",
    fontStyle: " normal",
    fontWeight: "700",
    fontSize: "10px",
    lineHeight: "12px",
    color: " #E03128",
  },
  Link: {
    textDecoration: "none",
    fontSize: "18px",
    float: "right",
    padding: "16px 40px 0 0px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: " 700",
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.06em",
    color: "#FF0000",
  },
  timer: {
    marginRight: "25px",
    paddingLeft: "11.5rem",
  },
  TimerExpired: {
    color: "#E03128",
    marginRight: "25px",
    paddingLeft: "11.5rem",
  },
  timerGrid: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    display: "flex",
    justifyContent: "right",
  },
}));
const FieldInput = styled.input`
  width: 40px;
  height: 40px;
  background: rgba(217, 217, 217, 0.1) !important;
  border: 1px solid #d4d4d4 !important;
  border-radius: 3px !important;
  border: 1px solid #333;
  text-align: center;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

function Login(props) {
  const history = useHistory();
  var auth = useContext(AuthContext);
  console.log("yi9huthfjfiog", auth);
  const classes = useStyles();
  const [timer, setTimer] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [code, setCode] = useState("");
  const handleChange = (code) => {
    setCode(code);
    if (code.length == 6) {
      setError("");
    }
  };
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("");
  const [message, setMessage] = React.useState("");
  const handleClose = (x) => {
    setOpen(x);
  };
  const handleFormSubmit = async (values) => {
    console.log("values-----", values);
    const userEmail = localStorage.getItem("emailForgot");
    console.log(userEmail, "userEmail");
    console.log(values, "values");
    if (code.length == 6) {
      try {
        const res = await axios({
          method: "POST",
          url: `${BASE_URL}/signUpOTPVerfiy`,

          data: {
            email: userEmail,
            OTP: values,
          },
        });

        console.log(res, "otpverify");

        if (res.data.success) {
          setLoader(false);
          setType("success");
          setOpen(true);
          setMessage("OTP has been verified.");
          setTimeout(() => {
            history.push("/Reset");
          }, 1000);
        } else {
          setType("error");
          setOpen(true);
          setMessage(res.response.data.message);
          setTimeout(() => {
            history.push("/verifyotp");
          }, 1000);
        }
      } catch (error) {
        setLoader(false);
        setType("error");
        setOpen(true);
        setMessage(error.response.data.message);
      }
    } else {
      setError("Invalid OTP");
      setType("error");
      setOpen(true);
      setMessage("Invalid OTP");
    }
  };

  // const handleResendOTP = async (values) => {
  //   console.log(values, "values");
  //   // setLoader(true);
  //   try {
  //     const res = await axios.post(
  //       `${BASE_URL}/forgetPaswordSentOTP`,
  //       {
  //         email: localStorage.getItem("emailForgot"),
  //       }
  //     );
  //     console.log(res.status, "api data");
  //     if (res.data.success) {
  //       // setLoader(false);

  //       toast.success("OTP sent successfully, Please check your email.");
  //       auth.setEndtime(moment().add(3, "m").unix());
  //       history.push("/verifyotp");
  //       setTimer(true);
  //       console.log(res.data.message);
  //     } else if (
  //       res.status === 400 &&
  //       res.data.message ===
  //         "OTP is Already Sent To Mail For Resend OTP Wait For 3 Min"
  //     ) {
  //       toast.warn("OTP is Already Sent To Mail For Resend OTP Wait For 3 Min");

  //     }

  //     return res;
  //   } catch (error) {
  //     console.log(error);

  //     if (
  //       error.response.status === 400 &&
  //       error.response.data.message ===
  //         "OTP is Already Sent To Mail For Resend OTP Wait For 3 Min"
  //     ) {
  //       toast.error(
  //         "OTP is Already Sent To Mail For Resend OTP Wait For 3 Min"
  //       );

  //     }

  //   }
  // };

  const handleChangeOTP = (e) => {
    const { maxLength, value, name } = e.target;
    console.log("maxLength", maxLength, value, name);
    const [fieldName, fieldIndex] = name.split("-");

    let fieldIntIndex = parseInt(fieldIndex, 10);

    // Check if no of char in field == maxlength
    if (value.length >= maxLength) {
      // It should not be last input field
      if (fieldIntIndex < 6) {
        // Get the next input field using it's name
        const nextfield = document.querySelector(
          `input[name=field-${fieldIntIndex + 1}]`
        );

        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  };

  return (
    <>
      <div>
        <CustomToast
          type={type}
          handleClose={handleClose}
          open={open}
          message={message}
        />
      </div>
      {calculateTimeLeft}
      <TopBar>
        <Page title="Verify OTP">
          <Grid className="d-flex height100">
            <Box className="loginForm">
              <Box className="signupbox">
                <Grid container direction={"column"}>
                  <Grid item style={{}}>
                    {/* <Logo
                width="110"
                style={{ padding: "15px 0", cursor: "pointer" }}
              /> */}
                    <Typography
                      variant="h5"
                      style={{
                        textAlign: "center",
                        fontSize: "45px",
                        color: "#000000",
                        marginTop: "-30%",
                        paddingLeft: "35px",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "20px",
                        lineHeight: "24px",
                        color: "#000000",
                        paddingTop: "10rem",
                      }}
                    >
                      Verification Code
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "18px",
                        color: "#343434",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "12px",
                        color: "#343434",
                        padding: "13px 10px 1px 53px",
                      }}
                    >
                      A 6- digit OTP has been sent to your registered e-mail.
                    </Typography>
                  </Grid>{" "}
                  <Grid item>
                    <Box className={classes.logosec}>
                      <Logo width="110" style={{ cursor: "pointer" }} />
                    </Box>
                  </Grid>
                  <Box className={classes.inputBox}>
                    <Grid item style={{ padding: "20px 19px 7px 69px" }}>
                      <OtpInput
                        value={code}
                        onChange={handleChange}
                        numInputs={6}
                        separator={<span style={{ width: "8px" }}></span>}
                        isInputNum={true}
                        shouldAutoFocus={true}
                        inputStyle={{
                          borderRadius: "8px",
                          width: "48px",
                          height: "48px",
                          fontSize: "20px",
                          color: "#000",
                          fontWeight: "400",
                          caretColor: "blue",

                          background: "rgba(217, 217, 217, 0.1)",
                          border: "1px solid #D4D4D4",
                          borderRadius: " 3px",
                        }}
                        focusStyle={{
                          border: "1px solid red",
                          outline: "none",
                        }}
                      />
                    </Grid>
                  </Box>
                  <Typography className={classes.error}>{error}</Typography>
                  <Box>
                    {auth.timeLeft && auth.timeLeft.seconds >= 0 ? (
                      <>
                        <Box display="flex" justifyContent="flex-end">
                          <Typography
                            variant="body1"
                            style={{
                              color: "red",
                              fontSize: "14px",
                              fontWeight: 500,
                              paddingLeft: "1rem",
                              marginRight: "29px",
                            }}
                          >
                            {" "}
                            {auth.timeLeft?.minutes} : {auth.timeLeft?.seconds}
                          </Typography>{" "}
                        </Box>
                      </>
                    ) : (
                      <Typography
                        variant="contained"
                        color="secondary"
                        style={{
                          float: "right",
                          color: "red",
                          padding: "3px 23px 0px 0px",
                          fontFamily: "Inter",
                          fontWeight: "500",
                          fontSize: "15spx",
                          lineHeight: "12px",
                          letterSpacing: "0.06em",
                          color: "#FF0000",
                        }}
                        fullWidth
                        onClick={() => {
                          handleFormSubmit();
                        }}
                        disabled={auth.timeLeft && auth.timeLeft.seconds > 0}
                      >
                        {" "}
                        Resend OTP{" "}
                      </Typography>
                    )}
                  </Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    type="submit"
                    disabled={isLoading}
                    onClick={() => handleFormSubmit(code)}
                  >
                    Submit
                  </Button>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Page>
      </TopBar>
    </>
  );
}

export default Login;
