/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import clsx from "clsx";

import {
  FaSignOutAlt,
  FaUserCircle,
  FaUser,
  FaRegEdit,
} from "react-icons/fa";
import {AiFillHome,AiOutlineDashboard} from "react-icons/ai"
import {FiPaperclip} from "react-icons/fi"
import {RiBarChartFill} from "react-icons/ri"
import {GiBackwardTime} from "react-icons/gi"
import {AiFillWallet} from "react-icons/ai"
import {CgTranscript} from "react-icons/cg"
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Slide,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import NavItem from "./NavItem";
import GroupIcon from "@material-ui/icons/Group";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { FaWarehouse, FaUserAlt } from "react-icons/fa";
import { GiFarmer, GiWheat, GiNewspaper, GiWantedReward } from "react-icons/gi";
import Collapse from "@material-ui/core/Collapse";
import ImageIcon from "@material-ui/icons/Image";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
const sections = [
  {
    items: [
      // {
      //   title: "Home",
      //   icon: AiFillHome,
      //   href: "/",
      // },
    ]},  

   
    {
      items: [
        {
          title: "Dashboard",
          icon: "/images/dash.svg",
          active:"/images/dashActive.svg",
          href: "/dash",
        },
      ]},

      {
        items: [
          {
        title: "User Management",
        icon: "/images/user.svg",
        active:"/images/userActive.svg",
        href: "/userManagement",
          }],
        },
        {
          items: [
            {
              title: "Trade Management",
              icon: "/images/trans.svg",
              active:"/images/transActive.svg",
              href: "/tradeManagement",
            },
          ],
        },
      {
        items: [
          {
        title: "Notification Management",
        icon: "/images/mix.svg",
        active:"/images/mixActive.svg",
        href: "/notification",
      },],  
    },
      {
        items: [
          {
        title: "Content Management",
        icon: "/images/content.svg",
        active:"/images/contentActive.svg",
        href: "/content",
      },],
    },
    {
      items: [
        {
      title: "Transaction History",
      icon: "/images/doollar.svg",
      active:"/images/dollarActive.svg",
      href: "/transacation",
    },],
  },
  {
    items: [
      {
    title: "Settlement",
    icon: "/images/settlement.svg",
    active:"/images/settlementActive.svg",
    href: "/settlement",
  },],
},
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: true,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        active={item.active}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        active={item.active}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "rgb(11, 52, 131)",
    backdropFilter: "blur(44px)",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
 backgroundImage: "url(/images/sides.svg)",
//  background: "pink",
 backgroundSize: "cover",
//  backgroundPosition: "center",
//  backgroundRepeat: "no-repeat",
//     backdropFilter: "blur(44px)",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  logoutButton: {
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    color: "#fff",

    "&:hover": {
      color: "#fff",
      background: "rgb(90 134 255) !important",
      borderRadius: "none !important",
    },
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: "2px",
  },
  buttonLeaf: {
    color: "#f5f7fac9",
    padding: "11px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    borderLeft: "solid 8px transparent",
    borderRadius: 0,
    fontSize: "13px",
    borderLeft: "3px solid transparent",
    "&:hover": {
      background: "rgb(90 134 255)",
      color: "#fff",
    },

    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  active: {
    borderLeft: "3px solid #fff",

    fontWeight: theme.typography.fontWeightRegular,
    background: "#263944",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "00e0b0",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [colorbg, setColor] = useState();
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const [open1, setOpen1] = React.useState(false);

  const handleClick = (event, index) => {
    setOpen1(!open1);
    setSelectedIndex(index);
  };
  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const content = (
    <Box
      height="100%"
      
      display="flex"
      flexDirection="column"
      className="example"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box
          mt={2}
          style={{
            display: "flex",
            paddingLeft:"20px",
            // justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
           
          }}
        >
          <Box>
          <Link to= "/dash"><img src="images/logoTonyDash.svg" /></Link>
          </Box>&nbsp;&nbsp;&nbsp;
     
        </Box>&nbsp;
       
      
        <Box pt={1}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location?.pathname,
              })}
            </List>
          ))}
        </Box>
    
      </PerfectScrollbar>

     
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          // className="drawerimg"
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;